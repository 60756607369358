<template>
  <div v-if = "permission.access">
    <div class = "row">
      <div class = "col-md-12">
        <b-card title = "Industry List" no-body>
          <b-card-body>
            <b-row class = "justify-content-between">
              <b-col
                  cols = "12"
                  md = "1"
                  class = "d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label class = "mr-1">Show</label>
                <Select
                    @on-change = "paginateChange"
                    v-model = "search.paginate"
                    :clearable = "false"
                >
                <Option :value = "10"> 10</Option>
                <Option :value = "30"> 30</Option>
                <Option :value = "50"> 50</Option>
                <Option :value = "80"> 80</Option>
                <Option :value = "100"> 100</Option>
                </Select>
              </b-col>
            </b-row>
            <div class = "row">
              <div
                  class = "col-xl-2"
                  v-for = "(item, index) in selected"
                  :key = "index"
              >
                <div class = "profile-wrapper center">
                  <div class = "profile-body mt-2 d-flex justify-content-between">
                    <div class = "IMAGE">
                      <img
                          class = "d-block"
                          :src = "item.photo || getImage(60, 60)"
                          alt = "dd"
                          width = "60"
                          height = "60"
                      />
                    </div>

                    <div class = "profile-details">
                      <h5
                          style = "margin-top: 15px; font-size: 12px; color: black"
                      >
                        {{ item.name }}
                      </h5>
                      <p class = "description" style = "line-height: 16px">
                        {{ item.designation }}
                      </p>
                    </div>
                    <p
                        style = "
                        margin-top: 10px;
                        margin-right: 15px;
                        cursor: pointer;
                      "
                        @click = "removeSelectedItem(index)"
                    >
                      X
                    </p>
                  </div>
                </div>
              </div>
              <div class = "col-xl-2">
                <b-button
                    variant = "primary"
                    type = "submit"
                    @click = "updateContact(1)"
                    :disabled = "form.busy"
                    class = "ml-1 mt-3"
                    v-if = "selected.length || permission.contactInfoupdate"
                >
                  Give Access Contact
                </b-button>
              </div>
            </div>
          </b-card-body>
          <div class = "table-responsive" >
            <table class = "table">
              <thead>
              <tr>
                <th class = "text-center">Sl</th>
                <th class = "px-5">Photo</th>
                <th>Candidate Info</th>
                <th>Preference</th>
                <th>DateTime</th>
                <th>Skill</th>
                <th class = "text-center">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if = "candidates.data.length"
                  v-for = "(candidate, index) in candidates.data" :key = "index">
                <td class = "align-middle text-center">
                  {{ candidates.meta.from + index }}
                </td>
                <td class = "align-middle text-center">
                  <b
                  ><img
                      style = "border-radius: 50%"
                      :src = "candidate.photo"
                      alt = "photo"
                      width = "70"
                  /></b>
                </td>
                <td>
                  <p><b>Name :</b> {{ candidate.candidate }}</p>
                  <p v-if = "candidate.email">
                    <b>Email :</b> {{ candidate.email }}
                  </p>
                  <p v-if = "candidate.phone_number">
                    <b>Phone :</b> {{ candidate.phone_number }}
                  </p>
                  <span
                      v-if = "candidate.is_contact == false"
                      class = "badge badge-secondary"
                  >
                      Hide Contact
                    </span>
                </td>
                <td>
                  <p>
                    <b>{{ candidate.job_title }}</b>
                  </p>
                  <p><b>Designation :</b> {{ candidate.designation }}</p>
                  <p><b>Experience :</b> {{ candidate.experiences }} Years</p>
                  <p>
                      <span :class = "getStatusClass(candidate.status)">
                        {{ getStatusText(candidate.status) }}
                      </span>
                  </p>
                </td>
                <td>
                  <p v-if = "candidate.contact_at">
                    <b>Contact At :</b> {{ candidate.contact_at }}
                  </p>
                  <p><b>Proposed By :</b> {{ candidate.proposed_by }}</p>
                  <p><b>Created At :</b> {{ candidate.created_at }}</p>
                </td>
                <td
                    width = "20%"
                    v-if = "candidate.skills && candidate.skills.length"
                >
                    <span
                        v-for = "(skill, index) in candidate.skills"
                        :key = "index"
                        style = "margin-right: 2px; margin-bottom: 2px"
                        :class = "{
                        'badge badge-secondary':
                          skill.proficiency === 'BEGINNER',
                        'badge badge-primary':
                          skill.proficiency === 'EXPERIENCED',
                        'badge badge-info': skill.proficiency === 'ADVANCED',
                        'badge badge-success': skill.proficiency === 'EXPERT',
                      }"
                    >
                      {{ skill.name + " (" + skill.proficiency + ") " }}
                    </span>
                </td>
                <td v-else>No skills Yet</td>
                <td class = "text-center">
                  <Tooltip content = "Q&A" placement = "top-end">
                    <button
                        class = "btn btn-primary btn-sm"
                        @click = "
                        questionModal(
                          candidate.question_answers,
                          candidate.cover_letter
                        )
                      "
                    >
                      <feather-icon icon = "HelpCircleIcon" size = "16"/>
                    </button>
                  </Tooltip>

                  <button
                      v-if = "permission.contactStatusupdate"
                      style = "margin-left: 5px"
                      class = "btn btn-success btn-sm"
                      @click = "statusModal(candidate.id, candidate.company_id)"
                  >
                    <feather-icon icon = "LayersIcon" size = "16"/>
                  </button>

                  <Tooltip content = "select" placement = "top-end">
                    <button
                        :class = "[
                        'btn',
                        'btn-sm',
                        candidate.is_contact !== false
                          ? 'btn-success'
                          : 'btn-primary',
                      ]"
                        style = "margin-left: 4px; font-size: 14px"
                        @click = "
                        info(
                          candidate.id,
                          candidate.candidate,
                          candidate.photo,
                          candidate.designation,
                          candidate.is_contact
                        )
                      "
                        :disabled = "
                        candidate.is_contact != false ||
                        isButtonDisabled(candidate.id)
                      "
                    >
                      <feather-icon icon = "CheckIcon"/>
                    </button>
                  </Tooltip>

                  <Tooltip content = "schedule">
                    <router-link
                        style = "margin-left: 5px"
                        class = "btn btn-info btn-sm"
                        :to = "
                        '/schedule/manage/' +
                        candidate.id +
                        '/' +
                        candidate.company_id +
                        '/' +
                        candidate.candidate +
                        '/' +
                        encodeURIComponent(candidate.photo) +
                        '/' +
                        candidate.candidate_id
                      "
                    >
                      <feather-icon icon = "CalendarIcon"/>
                    </router-link>
                  </Tooltip>
                </td>
              </tr>

              <tr v-if="!candidates.data.length">
                <td class="text-center" colspan="6">
                  <h4>No data found!</h4>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <Modal
              v-model = "proposedCandidateQuestionModal"
              footer-hide
              title = "Question and cover letter"
          >
            <h5 class = "mt-0">Cover letter</h5>
            <p>
              {{ cover_letter }}
            </p>
            <hr style = "margin-bottom: 2px !important"/>
            <hr style = "margin-top: 0 !important"/>
            <h5 class = "my-1">Questions and answers</h5>
            <div
                class = "text-left mb-1"
                v-if = "applicantQuestionAnswers.length"
                v-for = "(question, num) in applicantQuestionAnswers"
                :key = "num"
            >
              <h5 class = "mt-0">Q :{{ question.question }} ?</h5>
              <p>A :{{ question.answer }}</p>
              <hr/>
            </div>
          </Modal>
          <Modal
              v-model = "proposedCandidateStatusModal"
              footer-hide
              title = "Status update"
          >
            <b-form @submit.prevent = "statusUpdate()">
              <div class = "row">
                <div class = "col-xl-12">
                  <Select
                      filterable
                      style = "margin-right: 10px"
                      v-model = "statusForm.select_status"
                  >
                  <Option value = "1"> Pending</Option>
                  <Option value = "2"> Short listed</Option>
                  <Option value = "3"> Interviewing</Option>
                  <Option value = "4"> Selected</Option>
                  <Option value = "5"> Rejected</Option>
                  <Option value = "6"> Cancel interview</Option>
                  </Select>
                </div>
                <div class = "col-xl-4">
                  <b-button
                      variant = "primary"
                      type = "submit"
                      :disabled = "statusForm.busy"
                      class = "mt-2"
                  >
                    Update
                  </b-button>
                </div>
              </div>
            </b-form>
          </Modal>
          <b-card-body>
            <div class = "d-flex justify-content-between align-items-center">
              <span class = "text-muted"
              >Showing {{ candidates.meta.from }} to
                {{ candidates.meta.to }} of
                {{ candidates.meta.total }} entries</span
              >
              <pagination
                  :data = "candidates"
                  @pagination-change-page = "getResults"
                  align = "right"
                  :show-disabled = "true"
                  :limit = "5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import _ from "lodash";

export default {
  data() {
    return {
      proposedCandidateQuestionModal: false,
      proposedCandidateStatusModal: false,
      applicantQuestionAnswers: [],
      search: {
        search_data: "",
        status: "",
        paginate: 10,
        page: 1,
      },
      form: new Form({
        id: [],
        is_contact: true,
      }),
      statusForm: new Form({
        id: null,
        company_id: null,
        select_status: null,
      }),
      selected: [],
      cover_letter: null,
      candidates: {},
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
          .get(`/app/company/proposed/candidate${queryParams}`)
          .then((res) => {
            this.candidates = res.data;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    isButtonDisabled(candidateId) {
      return this.selected.some((item) => item.id === candidateId);
    },
    info(id, name, photo, designation, is_contact) {
      const isDuplicate = this.selected.some((item) => item.id === id);
      if (!isDuplicate) {
        this.selected.push({id, name, photo, designation, is_contact});
      }
    },
    removeSelectedItem(index) {
      this.selected.splice(index, 1);
    },
    updateContact() {
      this.form.id = [];
      this.selected.forEach((item) => {
        this.form.id.push(item.id);
      });

      this.form
          .put("/app/company/proposed/contact/update")
          .then((res) => {
            this.s(res.data.message);
            this.selected = [];
            this.getResults();
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    questionModal(applicantQuestionAnswers, cover_letter) {
      this.proposedCandidateQuestionModal = true;
      this.applicantQuestionAnswers = applicantQuestionAnswers;
      this.cover_letter = cover_letter;
    },

    statusModal(proposedId, companyId) {
      this.proposedCandidateStatusModal = true;
      this.statusForm.id = proposedId;
      this.statusForm.company_id = companyId;
    },

    statusUpdate() {
      this.statusForm
          .put("/app/company/proposed/status/update")
          .then((res) => {
            this.getResults();
            this.statusForm.id = null;
            this.statusForm.company_id = null;
            this.s(res.data.message);
            this.proposedCandidateStatusModal = false;
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },

    getStatusText(status) {
      return {
        1: "Pending",
        2: "Short Listed",
        3: "Interviewing",
        4: "Selected",
        5: "Rejected",
        6: "Cancel Interview",
      }[status];
    },
    getStatusClass(status) {
      return {
        1: "badge badge-warning",
        2: "badge badge-info",
        3: "badge badge-primary",
        4: "badge badge-success",
        5: "badge badge-danger",
        6: "badge badge-secondary",
      }[status];
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.proposal;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
<style scoped>
p {
  margin-bottom: 0px;
}

.profile-body {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 20px;
}

.profile-body img {
  margin: 10px 15px;
  border: 3px solid white;
  border-radius: 25%;
}

.description {
  font-size: 12px;
}

.profile-details h5 {
  margin-bottom: 0.1em;
}
</style>
